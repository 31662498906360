<template>
  <div 
    class="
      duck-form 
      add--project--form
    "
  >
    <form
      @submit.prevent="saveForm"
      autocomplete="off"
    >
      <fieldset 
        class="
          form-block
          add--project--block
        "
      >
        <div 
          class="form-container"
        >

          <SmallTitle 
            title="ที่ตั้งโครงการ" 
            class="bottom-line"
          />
          <AddressInput 
            ref="addressInput" 
          />


          <SmallTitle 
            title="ความพร้อมของงาน" 
            class="mt-20 bottom-line"
          />
          <ReadinessBlock
            ref="readinessBlock" 
          />
          
          <ProjectSubmitBlock text="ไปขั้นตอนต่อไป" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
// load library and variables
const _ = require('lodash/core');
import {apiRequest} from '@/utils/axios/axiosInstance.js';

// Load components
import AddressInput from '@/components/common/form/input/AddressInput.vue';
import ReadinessBlock from '@/components/project/form/input/ReadinessBlock.vue';
import ProjectSubmitBlock from '@/components/project/form/button/ProjectSubmitBlock.vue';
import SmallTitle from '@/components/common/SmallTitle.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  components: {
    AddressInput,
    ReadinessBlock,
    ProjectSubmitBlock,
    SmallTitle,
  },
  created() {
    // set page title
    this.$store.commit('setPageTitle', 'Add Project : Step 3');
    this.$store.commit('setHeadTitle', 'Add Project : Step 3');

    // hide main loader
    this.$store.commit('setLoaderShow', false);
  },

  /**
   * Methods
   */
  methods: {
    saveForm() {
    //   // this.$v.$touch()
      this.$refs.addressInput.$v.$touch();
      this.$refs.readinessBlock.$v.$touch();

      // if not validate error
      // update data to database by API
      if (!this.$refs.addressInput.$v.$invalid && !this.$refs.readinessBlock.$v.$invalid) {
        // show main loader
        this.$store.commit('setLoaderShow', true);

        let projectId = this.$route.params.id;
        let form = _.extend(
          { project_id: projectId },
          this.$refs.addressInput.$data.form,
          this.$refs.readinessBlock.$data.form
        );

        apiRequest
          .patch(
            '/project/',
            form
          )
          .then( (res) => {
            if (res.data.status == 200) {
              this.$router.push('/project/add/step4/' + projectId);
            } else {
              console.log(res.data);
              alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณารีเฟรชหน้านี้และลองใหม่อีกครั้ง !!!');
            }
          });
      }
    },
  }
}
</script>